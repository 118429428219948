import React from "react"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"

import { SiGooglemybusiness } from "react-icons/si"

import SEO from "../../components/Global/MetaHead"
import FeedbackHeader from "../../components/Layout/feedback-header"

const FeedbackGoodPage = ({ data }) => (
  <>
    <SEO
      title="Залиште відгук, будь ласка"
      description="Ваша думка дуже важлива для нас і буде корисна іншим клієнтам."
      image={`https:${data.ogImage.fixed.src}`}
      url="/feedback/good/"
      lang="uk"
      noindex="noindex"
    />
    <FeedbackHeader />
    <Container className="py-5" as="section">
      <h1 className=" mb-4">Можете залишити відгук?</h1>
      <p>Ваша думка дуже важлива для нас і буде корисна іншим клієнтам.</p>
      <p className="">Наша вдячність буде вічною!</p>

      <div className=" my-4">
        <Button
          variant="success"
          size="lg"
          href="https://g.page/licenziiukr/review?gm"
          className="mb-2 gtm-button"
          target="_blank"
          id="gtmButtonTop"
        >
          <SiGooglemybusiness className="feedback-icon" />
          Оцінити в Google
        </Button>
        <p className="text-muted">Потрібна реєстрація в Google</p>
      </div>

      <p className="pt-3 h3">Що написати?</p>
      <p className="pt-2 pb-2 ">
        Якщо не знаєте, що написати, є дуже простий рецепт змістовного відгуку:
      </p>
      <ol>
        <li>Напишіть, яку послугу замовляли.</li>
        <li>Додайте ім'я менеджера.</li>
        <li>
          Чи була консультація корисною, зрозумілою і чи був менеджер ввічливий?
        </li>
        <li>Чи дотрималися ми гарантованих сроків?</li>
      </ol>
      <p className="pt-3 pb-2 h3">Приклади змістовних відгуків:</p>
      <p className="pt-3 pb-2 ">
        Терміново потрібна була Чіп карта. Консультувала менеджерка Інна,
        отримав чіп Укрпоштою, як і обіцяли. Рекомендую.
      </p>
      <p className="pt-3 pb-2 ">
        Звернувся за ліцензією таксі, допомагав менеджер Андрій. Спасибі, вже
        працюю і можу рекомендувати Центр Ліцензій.
      </p>
      <div className="my-4">
        <Button
          variant="success"
          size="lg"
          href="https://g.page/licenziiukr/review?gm"
          className="mb-2 gtm-button"
          target="_blank"
          id="gtmButtonBottom"
        >
          <SiGooglemybusiness className="feedback-icon" />
          Написати відгук в Google
        </Button>
        <p className="text-muted">
          При натисканні на кнопку Вас буде перенаправлено на сторінку Центру
          Ліцензій на картах Google. Щоб додати відгук потрібна пошта на
          @gmail.com
        </p>
      </div>
    </Container>
  </>
)
export const query = graphql`
  {
    ogImage: contentfulAsset(
      file: { fileName: { eq: "center-licenziy-opengraph-min.png" } }
    ) {
      title
      id
      fixed(width: 620, height: 460) {
        src
      }
    }
  }
`

export default FeedbackGoodPage
